import React from 'react';

import SubpageLayout from "../components/subpage-layout"
import Seo from "../components/seo"


const Who = () => (
   <SubpageLayout question="Want to join in?" fact="Intercept is built on Drupal: the world’s largest open source community.">
      <Seo title="Who" />
      <header className="subpage--content-header">
        <h1>Who</h1>
        <h2>
          Who is using Intercept?
        </h2>
      </header>

      <section>
        <p>
          Intercept started as an idea at <a href="https://www.richlandlibrary.com/">Richland Library</a> (SC) and has since grown to be used by Richland, <a href="https://librarylearning.org/">RAILS</a> (IL), <a href="https://events.hmcpl.org/events">Huntsville-Madison County</a> and <a href="https://events.camdencountylibrary.org/">Camden County</a> (NJ) with several others waiting in the wings.
        </p>

        <p>
          The whole project began with the simple question of “How can staff better engage with customers who attend programming?” and, after working with <a href="https://atendesigngroup.com/">Aten Design Group</a> to build the tool, Intercept was born to answer that very question. It now powers Richland Library’s event calendars and room reservations while providing data collection (scan your library card at the event) and reporting. The latest version even allows customers to rate programs and make recommendations – with an API that provides connection options to your ILS.
        </p>

        <p>
          In 2020, Reaching Across Illinois Library System (<a href="https://librarylearning.org/">RAILS</a>), a consortium of 1300 academic, public, school, and special library agencies in northern and west-central Illinois, began using Intercept as their training calendar (for all 1,300 libraries).
        </p>

        <p>
          In 2021, <a href="https://events.camdencountylibrary.org/">Camden County Library</a> (NJ) became the latest member of the Intercept community. Camden stood up their own, stand-alone, instance of Intercept with some unique enhancements like color coding, audience badges and kit pick-ups. Very soon, they’ll also be rolling out the room reservation feature too.
        </p>

        <p>
          If you’re interested in becoming a member of the Intercept family (or just getting more details), email us at <a href="mailto:intercept@richlandlibrary.com">intercept@richlandlibrary.com</a>.
        </p>





      </section>
   </SubpageLayout>

)

export default Who